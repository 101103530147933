




































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { gustos_platosDto } from "@/shared/dtos/visualizacion_dieta/entrevista/gustos_platosDto";
@Component({
  components: {
    CompHeader: () => import("@/views/entrevista/CompHeaderLabel.vue"),
    TableSelect: () => import("@/components/DataTableSelect/TableSelect.vue")
  }
})
export default class CompGustosPaciente extends Vue {
  @PropSync("paciente", { default: -1 }) id_paciente!: number;

  public tab: any = null;

  public alimentos_nogustan: gustos_platosDto[] = [];
  public platos_nogustan: gustos_platosDto[] = [];

  public async created() {
    await nueva_platoModule.getall_platos_gustos_paciente(this.id_paciente);
    await alimentoModule.getall_alimentos_gustos_paciente(this.id_paciente);
    this.platos_nogustan = nueva_platoModule.NoGustan_nueva_plato_gustos;
    this.alimentos_nogustan = alimentoModule.NoGustan_nueva_alimento_gustos;
  }

  public get platos() {
    return nueva_platoModule.All_nueva_plato_gustos;
  }
  public get alimentos() {
    return alimentoModule.All_nueva_alimento_gustos;
  }

  @Watch("alimentos_nogustan")
  public setgustosalimentos() {
    alimentoModule.Set_alimentos_gustos(this.alimentos_nogustan);
  }

  @Watch("platos_nogustan")
  public setgustosplatos() {
    nueva_platoModule.Set_Platos_gustos(this.platos_nogustan);
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        value: "nombre",
        width: "50%"
      },
      { text: "Grupo", value: "grupo", width: "50%" }
    ];
  }

  public get tolheight() {
    return this.$vuetify.breakpoint.height - 412;
  }
}
