















import { Component, Vue, Watch } from "vue-property-decorator";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";

@Component({
  components: {
    CompHeader: () => import("@/views/entrevista/CompHeaderLabel.vue")
  }
})
export default class CompFiltrosPlatosTiempo extends Vue {
  public duracion_maxima: number = 0;
  public async created() {
    await nueva_platoModule.getduraciones();
    this.duracion_maxima = nueva_platoModule.duraciones.filter(
      x => x.seleccionado === true
    )[0].id;
  }

  public get duraciones() {
    return nueva_platoModule.duraciones;
  }

  @Watch("duracion_maxima")
  Setduracion_maxima() {
    nueva_platoModule.SetTiempoMaximo(
      nueva_platoModule.duraciones.filter(x => x.id === this.duracion_maxima)[0]
    );
  }
}
