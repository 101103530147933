import { BaseDto } from '@/shared/dtos/base-dto';
import { gustos_platosDto } from './gustos_platosDto';
import { id_texto_seleccionadoDto } from '@/shared/dtos/generalesDto/id_texto_seleccionadoDto';
import { horario_comidasViewDto } from '@/shared/dtos/horarios/horario_comidasViewDto';
import { PorcentajesGlobalesDieta } from '@/shared/dtos/horarios/PorcentajesGlobalesDieta';
import { dificultad_plato } from '@/shared/dtos/dificultad_plato';
import { comidas_horarios } from '../../horarios/comidas_horarios';

export class SendDataEntrevistaDto extends BaseDto {
    //paso 1
    public NoGustan_nueva_plato_gustos: gustos_platosDto[] = [];
    //paso 1.1
    public NoGustan_nueva_alimentos_gustos: gustos_platosDto[] = [];
    //paso 2
    public modelo_horarios_view: horario_comidasViewDto[] = [];
    public horario_opciones: comidas_horarios[] = [];
    //paso 3
    public PorcentajesGlobales: PorcentajesGlobalesDieta = new PorcentajesGlobalesDieta();
    //paso 4
    public dificultad_platos: dificultad_plato[] = [];
    //paso 5
    public tiempo_maximo: id_texto_seleccionadoDto = new id_texto_seleccionadoDto();
    //paso 6.1
    public postre_seleccionado_comida: id_texto_seleccionadoDto = new id_texto_seleccionadoDto();
    public postre_seleccionado_cena: id_texto_seleccionadoDto = new id_texto_seleccionadoDto();
    //paso 6.2
    public tipos_platos_asignar_comida: id_texto_seleccionadoDto[] = [];
    public tipos_platos_asignar_cena: id_texto_seleccionadoDto[] = [];
    //paso 6.3
    public sumplementos: boolean = false;

    public id_paciente: number = 0;
    public id_entrevista: number = 0;
    public id_cita: number = 0;
}