






















































































































import { Component, Vue, Ref, Watch, PropSync } from "vue-property-decorator";
import modelo_horarioModule from "@/store/modules/modelo_horario-module";
import { horario_comidasViewDto } from "@/shared/dtos/horarios/horario_comidasViewDto";

@Component({
  components: {
    CompHeader: () => import("@/views/entrevista/CompHeaderLabel.vue")
  }
})
export default class CompHorarios_y_comidas extends Vue {
  @PropSync("turno_laboral", { default: 6 }) id_turno_laboral!: number;
  public dt_horas: horario_comidasViewDto[] = [];

  public async created() {
    await modelo_horarioModule.getDataModelo_horario(this.id_turno_laboral);
    for (let i = 0; i < modelo_horarioModule.modelo_horarios_view.length; i++) {
      modelo_horarioModule.modelo_horarios_view[i].valido = true;
      modelo_horarioModule.modelo_horarios_view[i].error = "";
      modelo_horarioModule.modelo_horarios_view[i].dialog_show = false;
      Vue.set(this.dt_horas, i, modelo_horarioModule.modelo_horarios_view[i]);
    }
    modelo_horarioModule.onGetDataModelo_horario(this.dt_horas);
    modelo_horarioModule.SetOperativeHorarios_y_Comidas(this.dt_horas);
  }

  public get data_modelos1() {
    return this.dt_horas.filter(
      (x: horario_comidasViewDto) => x.id_comida <= 5
    );
  }
  public get data_modelos2() {
    return this.dt_horas.filter((x: horario_comidasViewDto) => x.id_comida > 5);
  }

  public get nombre_modelo() {
    if (this.dt_horas.length > 0) {
      return this.dt_horas[0].nombre_horario;
    }
    return "";
  }

  public get Comidas_Habilitadas() {
    return this.dt_horas.filter(
      (x: horario_comidasViewDto) => x.hora !== "00:00"
    ).length;
  }

  public get ComidaHabilitada() {
    return this.dt_horas.filter(
      (x: horario_comidasViewDto) => x.hora === "00:00" && x.id_comida === 4
    ).length;
  }

  public get CenaHabilitada() {
    return this.dt_horas.filter(
      (x: horario_comidasViewDto) => x.hora === "00:00" && x.id_comida === 7
    ).length;
  }

  public ValidateHours(e: horario_comidasViewDto) {
    //https://es.vuejs.org/v2/guide/list.html#Advertencias
    //valido las hora primero
    for (let i = 0; i < this.dt_horas.length; i++) {
      this.dt_horas[
        i
      ].valido = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        this.dt_horas[i].hora
      );
      if (!this.dt_horas[i].valido) {
        this.dt_horas[i].error = "Hora no valida";
      } else if (
        this.dt_horas[i].valido &&
        this.dt_horas[i].error !== "Esta hora esta repetida."
      ) {
        this.dt_horas[i].error = "";
      }
    }

    //siguen estando repetidas??
    let hora_repetidas_antguas = this.dt_horas.filter(
      (x: horario_comidasViewDto) => x.error === "Esta hora esta repetida."
    );

    for (let k = 0; k < hora_repetidas_antguas.length; k++) {
      hora_repetidas_antguas[k].error = "";

      let hora_repetidas_vv = this.dt_horas.filter(
        (x: horario_comidasViewDto) =>
          x.hora === hora_repetidas_antguas[k].hora &&
          x.valido === true &&
          x.hora !== "00:00"
      );
      if (hora_repetidas_vv.length > 1) {
        for (let j = 0; j < hora_repetidas_vv.length; j++) {
          hora_repetidas_vv[j].error = "Esta hora esta repetida.";
        }
      }
    }

    //luego valido las repetidas nuevas
    let hora_repetidas = this.dt_horas.filter(
      (x: horario_comidasViewDto) =>
        x.hora === e.hora && x.valido === true && x.hora !== "00:00"
    );
    if (hora_repetidas.length > 1) {
      for (let j = 0; j < hora_repetidas.length; j++) {
        hora_repetidas[j].error = "Esta hora esta repetida.";
      }
    }
    for (let i = 0; i < this.dt_horas.length; i++) {
      Vue.set(this.dt_horas, i, this.dt_horas[i]);
    }
    /*Luego se calculan los porcentajes para la pantalla de porcentajes de la dieta según estos horarios seleccionados*/
    modelo_horarioModule.SetOperativeHorarios_y_Comidas(this.dt_horas);
    /* Seteo los horarios*/
    modelo_horarioModule.onGetDataModelo_horario(this.dt_horas);
    this.$emit(
      "valido",
      this.dt_horas.filter(x => x.error !== "").length === 0 &&
        this.dt_horas.filter(x => x.id_comida === 4 && x.hora !== "00:00")
          .length === 1 &&
        this.dt_horas.filter(x => x.id_comida === 7 && x.hora !== "00:00")
          .length === 1
    );
  }

  /**
   * ValidateHourAndClosePiker
   */
  public ValidateHourAndClosePiker(e: horario_comidasViewDto) {
    e.dialog_show = false;
    this.ValidateHours(e);
  }

  public IsValid(): boolean {
    return (
      this.dt_horas.filter((x: horario_comidasViewDto) => x.valido === false)
        .length > 0 && this.Comidas_Habilitadas < 4
    );
  }
}
