


















































import { Component, Vue } from "vue-property-decorator";
import { UtilsPublicResources } from "@/utils/utils-public-resources";
import { ssmMessageService } from "@/shared/services/message-service";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import CompGustosPaciente from "@/views/entrevista/dieta-automatica/CompGustosPaciente.vue";
import CompHorarios_y_comidas from "@/views/entrevista/dieta-automatica/CompHorarios_y_comidas.vue";
import CompCalculoMacrosDieta from "@/views/entrevista/dieta-automatica/ComponentesMacrosDieta/CompCalculoMacrosDieta.vue";
import CompFiltrosPlatosDifDeLosPlatos from "@/views/entrevista/dieta-automatica/ComponentesUltimosFiltrosPlatos/CompFiltrosPlatosDifDeLosPlatos.vue";
import CompFiltrosPlatosTiempo from "@/views/entrevista/dieta-automatica/ComponentesUltimosFiltrosPlatos/CompFiltrosPlatosTiempo.vue";
import CompFiltrosPlatosTiposPlatos from "@/views/entrevista/dieta-automatica/ComponentesUltimosFiltrosPlatos/CompFiltrosPlatosTiposPlatos.vue";
import entrevistaModule from "@/store/modules/entrevista-module";
import modelo_horarioModule from "@/store/modules/modelo_horario-module";
import CompFin from "@/views/entrevista/CompFin.vue";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import { SendDataEntrevistaDto } from "@/shared/dtos/visualizacion_dieta/entrevista/SendDataEntrevistaDto";
import dificultad_platoModule from "@/store/modules/dificultad_plato-module";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { RouterNames } from "@/router/routernames";
import { dietaDto } from "@/shared/dtos/visualizacion_dieta/dietaDto";
@Component({
  components: {}
})
export default class EntrevistaAsistenteDietaAutomatica extends Vue {
  public onboarding: number = 0;
  public id_paciente: number = 0;
  public id_cita: number = 0;
  public id_entrevista: number = 0;
  public componentes_asistente: any[] = [];

  public async created() {
    let id_modelo_horario = 6;
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
    if (UtilsString.IsValidParamUrl(this.$route.query.id_cita)) {
      this.id_cita = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_cita)
      );
    }
    if (UtilsString.IsValidParamUrl(this.$route.query.id_entrevista)) {
      this.id_entrevista = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_entrevista)
      );
    }

    await entrevistaModule.getentrevista({
      id_paciente: this.id_paciente,
      id_entrevista: this.id_entrevista
    });
    await modelo_horarioModule.getmodelo_horarios();
    id_modelo_horario = modelo_horarioModule.modelo_horarios.filter(
      x => x.nombre === entrevistaModule.entrevista.turno_laboral
    )[0].id;

    this.componentes_asistente.push({
      key: 0,
      comp: CompGustosPaciente,
      valido: true,
      syncattrs: { paciente: this.id_paciente }
    });
    this.componentes_asistente.push({
      key: 1,
      comp: CompHorarios_y_comidas,
      valido: true,
      syncattrs: {
        turno_laboral: id_modelo_horario
      }
    });
    this.componentes_asistente.push({
      key: 2,
      comp: CompCalculoMacrosDieta,
      valido: true,
      syncattrs: {
        id_paciente: this.id_paciente,
        kcal: entrevistaModule.entrevista.calorias_dieta,
        peso: entrevistaModule.entrevista.peso
      }
    });
    this.componentes_asistente.push({
      key: 3,
      comp: CompFiltrosPlatosDifDeLosPlatos,
      valido: true,
      syncattrs: {}
    });
    this.componentes_asistente.push({
      key: 4,
      comp: CompFiltrosPlatosTiempo,
      valido: true,
      syncattrs: {}
    });
    this.componentes_asistente.push({
      key: 5,
      comp: CompFiltrosPlatosTiposPlatos,
      syncattrs: { suplemento: false },
      valido: true
    });
    this.componentes_asistente.push({
      key: 6,
      comp: CompFin,
      syncattrs: {
        desc: "Ha completado correctamente el asistente para crear la dieta",
        tit: "Crear dieta"
      },
      valido: true
    });
  }

  public SetValidacion(compo: any, valido: boolean) {
    compo.valido = valido;
    Vue.set(
      this.componentes_asistente,
      this.componentes_asistente.findIndex(x => x.key === compo.key),
      compo
    );
  }

  public Navigateto(index: number) {
    if (this.componentes_asistente[this.onboarding].valido) {
      this.onboarding = index;
    } else {
      ssmMessageService.toastinfo(
        "Este paso no es valido compruebe los erroes"
      );
    }
  }

  public next() {
    if (this.componentes_asistente[this.onboarding].valido) {
      this.onboarding =
        this.onboarding + 1 === this.componentes_asistente.length
          ? 0
          : this.onboarding + 1;
    } else {
      ssmMessageService.toastinfo(
        "Este paso no es valido compruebe los erroes"
      );
    }
  }
  public prev() {
    this.onboarding =
      this.onboarding - 1 < 0
        ? this.componentes_asistente.length - 1
        : this.onboarding - 1;
  }

  public async fin() {
    //hago la validacion de todo

    //Y procedemos a la creación del Objeto para enviar a la Api
    let send: SendDataEntrevistaDto = new SendDataEntrevistaDto();
    send.NoGustan_nueva_plato_gustos =
      nueva_platoModule.NoGustan_nueva_plato_gustos;
    send.NoGustan_nueva_alimentos_gustos =
      alimentoModule.NoGustan_nueva_alimento_gustos;
    send.horario_opciones = modelo_horarioModule.comidas_y_horarios;
    send.modelo_horarios_view = modelo_horarioModule.modelo_horarios_view;
    send.PorcentajesGlobales = modelo_horarioModule.PorcentajesGlobales;
    send.dificultad_platos = dificultad_platoModule.dificultad_platos;
    send.tiempo_maximo = nueva_platoModule.tiempo_maximo;
    send.postre_seleccionado_comida =
      nueva_platoModule.postre_seleccionado_comida;
    send.postre_seleccionado_cena = nueva_platoModule.postre_seleccionado_cena;
    send.tipos_platos_asignar_comida =
      nueva_platoModule.tipos_platos_asignar_comida;
    send.tipos_platos_asignar_cena =
      nueva_platoModule.tipos_platos_asignar_cena;
    send.sumplementos = this.componentes_asistente.find(
      x => x.key === 5
    ).syncattrs.suplemento;
    send.id_paciente = this.id_paciente;
    send.id_entrevista = this.id_entrevista;
    send.id_cita = this.id_cita;
    dieta_visualizacionModule
      .generarDietaAutomatica(send)
      .then((x: dietaDto) => {
        this.$router.push({
          name: RouterNames.dieta,
          query: {
            id_dieta: UtilsEncript.Encriptar(x.id.toString()),
            id_paciente: UtilsEncript.Encriptar(x.paciente.toString())
          }
        });
      })
      .catch(() => {
        ssmMessageService.toastinfo(
          "Ha habido un error al generar la dieta y no se ha podido completar correctamente."
        );
      });
  }

  public getimagen(imagen: string) {
    return UtilsPublicResources.getImageFromImages(imagen);
  }

  public get stilo() {
    return "height:" + this.tolheight + "px;overflow-x: hidden;";
  }

  public get tolheight() {
    return this.$vuetify.breakpoint.height - 185;
  }
}
